<template>
    <AdminToast />
    <ConfirmDialog></ConfirmDialog>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <employee-left-panel :data-item='dataItem'></employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />
                
                
				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                    <h3>Dosyalar</h3>
					
                    <p class='text-lg'>
						<b>Profil Fotoğrafı:</b> Personelin profil fotoğrafını minumum 640x640 maksimum 1280x1280 kare olarak yükleyin. Fotoğrafın kenar çevrelerini azaltalarak portreyi merkezleyin.<br>
                        <br>
                        <b>Personel Dosyaları:</b> Personelin işe alımlarında bazı dosyaların yüklenmesi gereklidir. Dosya formatları PDF, JPG veya PNG formatında olabilir.<br>
                        <br>
                        <b>İşten Çıkış:</b> Personelin işten ayrıldığı durumlarda istifa dilekçesi veya işten çıkarılma tutanağı yüklenmelidir.<br>
                        <br>
                        Disiplin tutanaklarını da bu bölümden yükleyebilirsiniz. (Örnek: İşe Geç Gelme, İşe Gelmemem, Kurallara Uymama vs.)<br>
                        <br>
                    </p>

                    <span><hr></span>
                    <span class='text-red-500 font-bold'>*</span> doldurulması zorunlu alanlardır.<br>

				</Sidebar>

				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>
                <employee-tab-menu :employee-id='employeeId'></employee-tab-menu>
                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Çalışan Profil Fotoğrafı</label>

                        <div class='col-12 md:col-8'>
                        </div>

                        <div class='col-12 md:col-2'>
                            <FileUpload choose-label='Yükle' mode='basic' name='upload[]' :url='photoUploadUrl'
                                        :auto='true' @before-upload='photoUploadStarted'
                                        @upload='photoUploadFinished' />
                        </div>
                    </div>

                    <hr class='mb-5'>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Dosya Tipi</label>

                        <div class='col-12 md:col-8'>
                            <Dropdown id='fileType' v-model='selectedFileType' :options='fileTypes' optionLabel='name'
                                      option-value='name' placeholder='Seçim Yapın'
                                      @change='fileTypeChanged'></Dropdown>
                        </div>

                        <div class='col-12 md:col-2'>
                            <FileUpload choose-label='Yükle' mode='basic' name='demo[]' :url='uploadUrl' :auto='true'
                                        @before-upload='uploadStarted' @upload='uploadFinished' />
                        </div>
                    </div>

                    <DataTable :value='dataList' :paginator='true' class='p-datatable-gridlines' :rows='20' dataKey='id'
                               :rowHover='true' filterDisplay='menu' :loading='loadingIndicator'
                               responsiveLayout='scroll'>

                        <template #empty>
                            Kayıtlı dosya bulunamadı.
                        </template>

                        <template #loading>
                            Dosya listesi yükleniyor. Lütfen bekleyin.
                        </template>

                        <Column field='insertDate' header='Tarih' filterField='insertDate' :showFilterMatchModes='false'
                                style='width:14rem'>
                            <template #body='{data}'>
                                {{ formatDate(data.insertDate) }}
                            </template>
                        </Column>

                        <Column field='fileType' header='Dosya Tipi' filterField='fileType'
                                :showFilterMatchModes='false'>
                        </Column>

                        <Column field='managerUser.name' header='Yükleyen' filterField='fileType'
                                :showFilterMatchModes='false'>
                            <template #body='{data}'>
                                {{ data.managerUser.name + ' ' + data.managerUser.surName }}
                            </template>
                        </Column>

                        <Column field='FilePath' header='Görüntüle' bodyClass='text-center' style='width:7.5rem'
                                filterField='insertDate' :showFilterMatchModes='false'>
                            <template #body='{data}'>
                                <a :href='"http://image.a1catering.com.tr" + data.filePath' target='_blank'>
                                    <Button icon='pi pi-eye' class='p-button-rounded p-button-primary' />
                                </a>
                            </template>
                        </Column>

                        <Column header='Sil' bodyClass='text-center' style='width:7.5rem' :exportable='false'>
                            <template #body='slotProps'>
                                <Button icon='pi pi-trash' class='p-button-rounded p-button-danger'
                                        @click='deleteFile(slotProps.data)' />
                            </template>
                        </Column>

                    </DataTable>
                </div>

            </div>

        </div>

    </div>

</template>

<script>

import {
    showErrorMessage,
    showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeLeftPanel from './employeeLeftPanel';
import EmployeeTabMenu from './employeeTabMenu';
import EmployeeService from '../../services/employeeService';
import { getEmployee } from '../common/commonFunctions';
import store from '@/store';
import moment from 'moment';
import ProjectFileService from '../../services/projectFileService';
import { getActiveProjectId, getUserType } from '../common/commonConstantFunctions';

export default {
    components: { EmployeeTabMenu, EmployeeLeftPanel, AdminToast },
    _employeeService: null,
    _projectFileService: null,
    created() {
        this._employeeService = new EmployeeService();
        this._projectFileService = new ProjectFileService();
        this.employeeId = this.$route.params.employeeId;
        this.userId = store.getters.getUserId;
        this.photoUploadUrl = process.env.VUE_APP_MAIN_API + '/api/upload/static-image?fileType=ProfilePhoto&resourceId=' + this.employeeId + '&resourceType=Employee';
        this.uploadUrl = process.env.VUE_APP_MAIN_API + '/api/upload/file?fileType=' + this.selectedFileType + '&resourceId=' + this.employeeId + '&resourceType=Employee&managerUserId=' + this.userId;
    },
    async mounted() {
        this.dataItem = await getEmployee(this.employeeId);
        this.projectId = getActiveProjectId();
        if (this.dataItem.activeProject != null && this.dataItem.activeProject.id != this.projectId && getUserType() != 'Admin') {
            this.$router.push({ name: 'access' });
            return;
        }
        await this.getProjectFileList();
    },
    data() {
        return {
            selectedFileType: 'İkametgah Belgesi',
            loadingIndicator: false,
            dataList: [],
            fileTypes: [
                {
                    name: 'İkametgah Belgesi',
                    id: 1,
                },
                {
                    name: 'Nüfus Cüzdanı Fotokopisi',
                    id: 2,
                },
                {
                    name: 'Nüfus Kayıt Örneği',
                    id: 3,
                },
                {
                    name: 'Vesikalık Fotoğraf',
                    id: 4,
                },
                {
                    name: 'Ehliyet Fotokopisi',
                    id: 5,
                },
                {
                    name: 'Kan Grubu Kartı',
                    id: 6,
                },
                {
                    name: 'Evlilik Cüzdanı Fotokopisi',
                    id: 7,
                },
                {
                    name: 'Adli Sicil Kaydı Belgesi',
                    id: 8,
                },
                {
                    name: 'Diploma',
                    id: 9,
                },
                {
                    name: 'Askerlik Durum Belgesi',
                    id: 10,
                },
                {
                    name: 'Sağlık Raporu',
                    id: 12,
                },
                {
                    name: 'İş Sözleşmesi',
                    id: 13,
                },
                {
                    name: 'Gizlilik Sözleşmesi',
                    id: 14,
                },
                {
                    name: 'İstifa Dilekçesi',
                    id: 15,
                },
                {
                    name: 'İşten Çıkarılma Tutanağı',
                    id: 16,
                },
                {
                    name: 'Disiplin Tutanağı',
                    id: 17,
                },
                {
                    name: 'Diğer',
                    id: 18,
                },
                {
                    name: 'Hijyen Belgesi',
                    id: 19,
                },
                {
                    name: 'Portör Muayenesi',
                    id: 20,
                },
                {
                    name: 'SGK Giriş Bildirgesi',
                    id: 21,
                },
                {
                    name: 'SRC Belgesi',
                    id: 22,
                },
                {
                    name: 'SGK Çıkış Bildirgesi',
                    id: 23,
                },
                {
                    name: 'İş Kazası Raporu',
                    id: 24,
                },
                {
                    name: 'Analık Raporu',
                    id: 25,
                },
                {
                    name: 'Hastalık Raporu',
                    id: 26,
                },
                {
                    name: 'Yıllık İzin Formu',
                    id: 27,
                },
                {
                    name: 'İbraname',
                    id: 28,
                },


            ],
            uploadUrl: '',
            photoUploadUrl: '',
            firmId: 0,
            returnField: '',
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                personName: '',
                personPhone: '',
                personEmail: '',
                employeePhoto: 'EmployeePhoto/LogoDefault.png',
            },
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async getProjectFileList() {
            let fileResponse = await this._projectFileService.getAllProjectFiles(this.employeeId, 'Employee');
            if (fileResponse.isSuccess) {
                this.dataList = fileResponse.data;
            }
        },
        async save() {

            if (this.validateForm()) {
                this.dataItem.employeePhoto = this.dataItem.employeePhoto.replace('./', '');
                const updateResponse = await this._employeeService.updateEmployeePhoto(this.employeeId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Çalışan Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Çalışan Güncellemede Sorun İle Karşılaşıldı');
                }
            } else {
                showErrorMessage(this, 'Çalışan Güncellemede Sorun İle Karşılaşıldı');
            }

        },
        validateForm() {
            if (this.dataItem.name == null || this.dataItem.name == '') {
                showValidationMessage(this, 'Çalışan Adı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.surName == null || this.dataItem.surName == '') {
                showValidationMessage(this, 'Çalışan Soy Adı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.identityNumber == null || this.dataItem.identityNumber == '') {
                showValidationMessage(this, 'Çalışan T.C. Kimlik No Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },
        onFileSelected(event, fieldName) {
            this.$data['dataItem'][fieldName] = event.data.path;
            this.$refs.op.hide();
        },
        openFileBrowser(fieldName) {
            this.returnField = fieldName;
            this.$refs.op.toggle(event);
        },
        async photoUploadFinished(event) {
            this.dataItem.employeePhoto = event.xhr.responseText;
            await this.save();
            this.loadingIndicator = false;
        },
        photoUploadStarted() {
            this.loadingIndicator = true;
        },

        async uploadFinished() {
            showSuccessMessage(this, 'Dosya Yükleme İşlemi Başarılı');
            await this.getProjectFileList();
            this.loadingIndicator = false;
        },
        uploadStarted() {
            this.loadingIndicator = true;
        },

        fileTypeChanged() {
            this.uploadUrl = process.env.VUE_APP_MAIN_API + '/api/upload/file?fileType=' + this.selectedFileType + '&resourceId=' + this.employeeId + '&resourceType=Employee&managerUserId=' + this.userId;
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },
        deleteFile(item) {
            this.$confirm.require({
                message: 'Dosyayı Silmek İstediğinize Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let deleteResponse = await this._projectFileService.deleteProjectFile(item.id);
                    if (deleteResponse.isSuccess) {
                        showSuccessMessage(this, 'Silme İşlemi Başarılı');
                        await this.getProjectFileList();
                    } else {
                        showErrorMessage(this, 'Silme İşlemi Sırasında Hata Oluştu');
                    }
                },
            });
        },
    },
};

</script>


<style scoped>

</style>
