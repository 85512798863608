import { deleteDataById, getListData } from './commonService';

export default class ProjectFileService {
    #defaultUrl = "/api/UploadedFile";
    #defaultResourceName = "Proje Dosyaları";

    async deleteProjectFile(projectFileId) {
        return await deleteDataById(this.#defaultUrl, projectFileId, this.#defaultResourceName);
    }
    async getAllProjectFiles(resourceId, resourceType) {
        return await getListData(this.#defaultUrl + "/query?ResourceId=" + resourceId + "&resourceType=" + resourceType, this.#defaultResourceName);
    }

}