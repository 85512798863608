<template>
    <TabMenu :model='nestedRouteItems' class='mb-5' />
</template>
<script>

export default {
    name: 'EmployeeTabMenu',
    props: {
        employeeId: {},
    },
    data() {
        return {
            nestedRouteItems: [],
        };
    },
    created() {
        this.nestedRouteItems = [
            {
                label: 'Temel Bilgiler',
                to: '/Employee/Edit/'+ this.employeeId
            },
            {
                label: 'Kişisel Bilgiler',
                to: '/Employee/Information/'+ this.employeeId
            },
            {
                label: 'İletişim Bilgileri',
                to: '/Employee/Contact/' + this.employeeId
            },
            {
                label: 'Dosyalar',
                to: '/Employee/Files/' + this.employeeId
            }

        ];
    },
};
</script>